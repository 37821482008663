import { ReactElement } from 'react';
import Button from './Button';
import styles from '../styles/QuickLink.module.css';
import { useApp } from '../providers/App';
import ModalStatus from '../const/modal';

interface Link {
  cta: string;
  url: string;
}

interface QuickLinkCardProps {
  title: string;
  bgImage: string;
  highlight: ReactElement;
  description: ReactElement;
  links: Link[];
}

const QuickLinkCard = ({ title, bgImage, highlight, description, links }: QuickLinkCardProps) => {
  const { setGlobalModalStatus } = useApp();
  return (
    <div>
      <h1 className="font-labBold text-sm font-bold leading-sectionTitles text-gray-600 uppercase my-5">
        {title}
      </h1>
      <div
        data-testid="QuickLinkCardContent"
        className={`${bgImage} ${styles.quickImage} bg-white flex items-center rounded-lg pb-7 pl-4 sm:pb-10 sm:pl-10 shadow-sm`}
      >
        <div>
          <h4 className="text-base w-52 mt-24 font-tommyBold sm:text-2xl sm:mt-28 mb-3 sm:w-80">
            {description}
          </h4>
          <p className="text-xs font-labMedium h-6 text-gray-600 mt-5 mb-7 tracking-wider">
            {highlight}
          </p>
          {links.map((link, index) => {
            const isUpgrade = link.cta === 'Upgrade';
            return (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                btnContext={link.cta}
                canActivate
                textSize="text-sm"
                href={isUpgrade ? undefined : link.url}
                rightIconName={isUpgrade ? 'lock' : undefined}
                handleClick={
                  isUpgrade ? () => setGlobalModalStatus(ModalStatus.Upgrade) : undefined
                }
                iconSize={isUpgrade ? 14 : 24}
                target="_blank"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuickLinkCard;
