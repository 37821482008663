import { useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import Config from '../../../config/Config';
import OnboardingCard from '../OnboardingCard';
import Button from '../../Button';
import Modal from '../../Modal';
import styles from '../../../styles/SlackCommunity.module.css';
// import { useUser } from '../../../providers/Auth';

const staticContent = {
  title: 'Download Discord',
  description:
    'Access our community everywhere you go, install Discord on your desktop and mobile devices. Explore the channels and ask questions.',
};
const SlackCommunityComponent = () => {
  const [openModal, setOpenModal] = useState(false);
  // const user = useUser();
  // const handleOpen = () => setOpenModal(true);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'slack-community';

  const downLoadSlackButton = (
    <Button
      btnContext="Download Discord"
      canActivate
      textSize="text-sm"
      href={`${Config.DISCORD_URL}/download`}
      target="_blank"
    />
  );

  const rightIcon = (
    <div
      className={`flex rounded-custom bg-black justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <embed
        src="/icons/qr-discord.svg"
        width={isMobile ? '40' : '60'}
        height={isMobile ? '40' : '60'}
        tabIndex={-1}
      />
    </div>
  );

  // const howItWorksButton = (
  //   <Button
  //     btnContext="How It Works"
  //     handleClick={handleOpen}
  //     leftIconName="triangle-right"
  //     canActivate
  //     secondary
  //     textSize="text-sm"
  //     padding="px-2"
  //   />
  // );
  const closeModal = () => {
    setOpenModal(false);
  };
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
    rightButtons: [downLoadSlackButton], // [downLoadSlackButton, howItWorksButton],
  };

  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
      <Modal openModal={openModal} handleClose={closeModal}>
        <iframe
          className={`w-videoModal h-videoModal rounded ${styles.videoModal}`}
          title="slack"
          allow="autoplay;"
          src={`${Config.LOOM_URL}/embed/8f55b2c71ee249b08eaa4785a49f7f24?autoplay=1`}
          frameBorder="0"
          allowFullScreen
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        />
      </Modal>
    </>
  );
};
const SlackCommunity = {
  component: SlackCommunityComponent,
  staticContent,
};
export default SlackCommunity;
