/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Image from 'next/image';
import { MouseEventHandler } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import styles from '../../styles/StepControl.module.css';
import { OnboardingCardStaticProps } from './OnboardingCard';

interface StepControlProps {
  onboardingCardContent: OnboardingCardStaticProps;
  onboardingState: string;
  stepNum: number;
  toggleFunction: Function;
  incrementFunction: MouseEventHandler<HTMLButtonElement>;
  decrementFunction: MouseEventHandler<HTMLButtonElement>;
}
const StepControl = ({
  onboardingCardContent,
  onboardingState,
  stepNum,
  toggleFunction,
  incrementFunction,
  decrementFunction,
}: StepControlProps) => {
  const { stepId, title } = onboardingCardContent;
  const isBreakpoint = useMediaQuery('(max-width: 768px)');
  return (
    <div className="flex items-start">
      <div
        id={stepId}
        data-testid={stepId}
        tabIndex={isBreakpoint ? -1 : 0}
        className={`${
          isBreakpoint ? 'cursor-default' : ''
        }  cursor-pointer transition duration-350 ease-in-out w-full`}
        onClick={(e) => {
          if (isBreakpoint) return;
          toggleFunction(e);
        }}
        onKeyPress={(e) => {
          if (isBreakpoint) return;
          toggleFunction(e);
        }}
      >
        {!isBreakpoint ? (
          <div
            id="stepSelectionIndicator"
            data-testid="stepSelectionIndicator"
            className={`${
              onboardingState === stepId ? 'bg-red-500' : 'bg-gray-300'
            } rounded-md h-1 mt-4 `}
          />
        ) : null}
        <div className={`${styles.parent}  text-left`}>
          <p
            id={stepId}
            className={`${styles.step} ${
              onboardingState === stepId ? 'text-gray-700' : 'text-gray-400'
            } ${
              isBreakpoint ? 'text-xxs' : 'text-xs'
            } hover:text-gray-700 font-bold mt-4 uppercase transition duration-350 ease-in-out`}
          >
            Step {stepId}
          </p>
          <div className={`${isBreakpoint ? 'mb-6' : ''} flex`}>
            <p
              id={stepId}
              className={`${styles.description} ${
                onboardingState === stepId ? '' : 'text-gray-400'
              } hover:text-gray-1000 text-sm font-bold transition duration-350 ease-in-out w-full`}
            >
              {title}
            </p>
            {isBreakpoint ? (
              <p className="flex items-center gap-2">
                <button
                  id={`decrementBtn-${stepId}`}
                  data-testid={`decrementBtn-${stepId}`}
                  className={+stepId === 1 ? `${styles.laststep}` : `${styles.control}`}
                  type="button"
                  tabIndex={0}
                  onClick={(e) => decrementFunction(e)}
                  disabled={+stepId === 1}
                >
                  <Image
                    src="/icons/angle-bracket-left.svg"
                    alt="angle-bracket-left"
                    width={16}
                    height={16}
                  />
                </button>
                <button
                  id={`incrementBtn-${stepId}`}
                  className={+stepId === stepNum ? `${styles.laststep}` : `${styles.control}`}
                  type="button"
                  tabIndex={0}
                  onClick={(e) => incrementFunction(e)}
                  disabled={+stepId === stepNum}
                >
                  <Image
                    src="/icons/angle-bracket-right.svg"
                    alt="angle-bracket-right"
                    width={16}
                    height={16}
                  />
                </button>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepControl;
