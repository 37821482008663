import Image from 'next/image';
import Link from 'next/link';
import ModalStatus from '../const/modal';
import { useApp } from '../providers/App';
import styles from '../styles/EducationCenter.module.css';

interface QuickLinkCardProps {
  title: string;
  description: string;
  imageSrc: string;
  link: string;
  isUpgrade?: boolean;
}

const EducationCenterCard = ({
  title,
  description,
  link,
  imageSrc,
  isUpgrade,
}: QuickLinkCardProps) => {
  const { setGlobalModalStatus } = useApp();
  const handleOpen = (e: any) => {
    if (isUpgrade) {
      e.preventDefault();
      setGlobalModalStatus(ModalStatus.Upgrade);
    }
  };
  const iconSize = isUpgrade ? 14 : 8;
  return (
    <div
      data-testid="educationCard"
      className={`${styles.textFix} h-full cursor-pointer rounded-xl transition transform duration-200 shadow-sm hover:shadow-lg hover:-translate-y-0.5`}
    >
      <Link href={isUpgrade ? '#' : link} passHref>
        <a
          title={title}
          target="_blank"
          className="flex flex-row-reverse lg:flex-col h-full"
          onClick={handleOpen}
          tabIndex={0}
          href="#"
        >
          <div className={`bg-ecBannerBg relative  ${styles.banner}`}>
            <div className="absolute bottom-4 left-4">
              <div className="flex items-center justify-center">
                <Image src={imageSrc} alt="arrow" width={64} height={58} />
              </div>
            </div>
            <div className={`${isUpgrade ? 'right-20' : 'right-4'} absolute top-4 h-6 w-6`}>
              <div
                className={`${
                  isUpgrade ? 'text-xxs font-bold tracking-wide w-24' : 'w-6'
                } rounded-full bg-white h-6 flex gap-1 items-center justify-center`}
              >
                {isUpgrade ? <span>UPGRADE</span> : null}
                <Image
                  src={`/icons/${isUpgrade ? 'lock-black' : 'top-right-arrow'}.svg`}
                  alt="arrow"
                  width={iconSize}
                  height={iconSize}
                />
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <span className="font-labMedium text-sm md:text-base text-gray-1000 pb-2">{title}</span>
            <span className={styles.description}>{description}</span>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default EducationCenterCard;

EducationCenterCard.defaultProps = {
  isUpgrade: false,
};
