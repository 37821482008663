import { useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import Config from '../../../config/Config';
import OnboardingCard from '../OnboardingCard';
import Button from '../../Button';
import Modal from '../../Modal';
import { ROLES, useAuth } from '../../../providers/Auth';

const staticContent = {
  title: 'Stay ahead of the game',
  description:
    'At SoleSavy, we know sneakers. Learn how to get the most out of your membership and read up on the latest and greatest buying methods.',
};
const EducationCenterComponent = () => {
  const [openModal, setOpenModal] = useState(false);
  const { hasRole } = useAuth();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'education-center';

  const findResourcesButton = (
    <Button
      btnContext="Find Resources"
      href={`${Config.EDUCATION_CENTER}/collections/2551058-getting-started`}
      canActivate
      textSize="text-sm"
      target="_blank"
    />
  );
  const antiBotCourseButton = (
    <Button
      btnContext={hasRole(ROLES.drops) ? 'Drop Alerts Course' : 'Anti-Bot Course'}
      href={`${Config.EDUCATION_CENTER}/articles/5354579-pre-cart-method-how-to-beat-the-bots`}
      canActivate
      secondary
      border
      textSize="text-sm"
      target="_blank"
    />
  );

  const rightIcon = (
    <div
      className={`flex rounded-custom bg-black justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <embed
        src="/icons/ss-logo-on-black.svg"
        width={isMobile ? '48' : '80'}
        height={isMobile ? '48' : '80'}
        tabIndex={-1}
      />
    </div>
  );

  const closeModal = () => {
    setOpenModal(false);
  };
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
    rightButtons: [findResourcesButton, antiBotCourseButton],
  };

  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
      <Modal openModal={openModal} handleClose={closeModal}>
        <iframe
          className="w-videoModal h-videoModal border-2 border-black rounded"
          title="slack"
          allow="autoplay;"
          src={`${Config.LOOM_URL}/embed/8f55b2c71ee249b08eaa4785a49f7f24?autoplay=1`}
          frameBorder="0"
          allowFullScreen
        />
      </Modal>
    </>
  );
};
const EducationCenter = {
  component: EducationCenterComponent,
  staticContent,
};
export default EducationCenter;
