import { useMediaQuery } from 'beautiful-react-hooks';
import OnboardingCard from '../OnboardingCard';
import Button from '../../Button';
import { useUser } from '../../../providers/Auth';
import Config from '../../../config/Config';

const staticContent = {
  title: 'Access the Discord Community',
  description:
    'To access the SoleSavy Discord, please connect your Discord account to your premium membership using the link below.',
};
const SlackInvitationComponent = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'slack-invitation';
  const user = useUser();
  const homeUrl = encodeURIComponent(Config.APP_URL);
  const discordLink = `https://discord.com/oauth2/authorize?client_id=1250613203579179040&response_type=code&redirect_uri=${homeUrl}%2Fapi%2Fv1%2Fauth%2Fdiscord-oauth-redirect&scope=identify+email+guilds.join&state=${user?.id}`;
  const rightIcon = (
    <div
      className={`flex rounded-custom bg-slackPurple justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <embed
        src="/icons/discord-mark-white.svg"
        width={isMobile ? '40' : '60'}
        height={isMobile ? '40' : '60'}
        tabIndex={-1}
      />
    </div>
  );

  const rightButton = (
    <Button
      btnContext="Connect Discord"
      canActivate
      textSize="text-sm"
      target="_blank"
      href={discordLink}
      rightIconName="chevron_right_white"
      iconSize={14}
    />
  );
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
    rightButtons: [rightButton],
  };

  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
    </>
  );
};
const SlackInvitation = {
  component: SlackInvitationComponent,
  staticContent,
};
export default SlackInvitation;
