import { useMediaQuery } from 'beautiful-react-hooks';
import Image from 'next/image';
import Config from '../../../config/Config';
import OnboardingCard from '../OnboardingCard';

const appleButton = (
  <a href={Config.COLLECT_IOS_APP} className="w-32">
    <Image src="/images/apple.svg" alt="apple" width="32" height="9" layout="responsive" />
  </a>
);

const androidButton = (
  <a href={Config.COLLECT_ANDROID_APP} className="w-32">
    <Image src="/images/google.svg" alt="google" width="32" height="9" layout="responsive" />
  </a>
);

const staticContent = {
  stepId: '3',
  title: 'Showcase, Buy, Sell, Trade',
  description:
    'Access the marketplace built by sneakerheads, for sneakerheads. Download the app and sign in with your SoleSavy credentials.',
  rightButtons: [appleButton, androidButton],
};
const CollectAppComponent = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'solesavy-app';
  const rightIcon = (
    <div
      className={`flex rounded-custom bg-black justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <Image src="/images/qr-collect-app.svg" width="80" height="80" />
    </div>
  );
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
  };
  return <OnboardingCard onboardingCardContent={content} />;
};
const CollectApp = {
  component: CollectAppComponent,
  staticContent,
};
export default CollectApp;
