import { loadIntercom } from 'next-intercom';
import Config from '../config/Config';
import User from '../interfaces/user';

const loadMessenger = async (user: User) => {
  loadIntercom({
    appId: Config.INTERCOM_APP_ID,
    email: user.email,
    name: user.firstName || user.displayName,
    ssr: false,
    initWindow: true,
    delay: 0,
    user_hash: user.hash,
  });
};

export default loadMessenger;
