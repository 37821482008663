import type { AppProps } from 'next/app';

import styles from '../styles/Home.module.css';

import UtilitySection from '../components/UtilitySection';
import withAuth from '../hocs/withAuth';
import { useUser, ROLES, useAuth } from '../providers/Auth';
import { useFeatureHub } from '../providers/FeatureHub';
import SanityAPIService from '../services/Sanity';
import Release from '../interfaces/release';
import QuickLinksSection from '../components/QuickLinksSection';
import Greeting from '../components/Greeting';
import OnboardingSection from '../components/Onboarding/OnboardingSection';

import DashboardLayout from '../components/DashboardLayout';
import AppLaunchSection from '../components/AppLaunchSection';
import loadMessenger from '../utils/messenger';
import { isTesting } from '../utils';
import EducationCenterSection from '../components/EducationCenterSection';
import LoadingSpinner from '../components/LoadingSpinner';
import ReleaseUpcomingSection from '../components/Release/ReleaseUpcomingSection';
import IntercomAPIService from '../services/Intercom';
import Collection from '../interfaces/collection';

const apiService: any = SanityAPIService();
const intercomService: any = IntercomAPIService();

interface CustomAppProps extends AppProps {
  upcomingReleases: Release[] | [];
  collections: Collection[] | null;
  releaseError: boolean;
}

const pageName = 'Dashboard';

const Home = ({ upcomingReleases, collections, releaseError }: CustomAppProps) => {
  const user = useUser();
  const { hasRole } = useAuth();
  const {
    features: { showReleases },
  } = useFeatureHub();

  if (!user) return <LoadingSpinner pageName={pageName} />;
  loadMessenger(user);
  return (
    <DashboardLayout pageName={pageName}>
      <div className={styles.container}>
        <main className={styles.main}>
          <div className="flex justify-between lg:items-end flex-col lg:flex-row gap-y-7 lg:gap-0 pb-10 lg:pb-20">
            <Greeting displayName={user.firstName} />
            <AppLaunchSection />
          </div>
          <OnboardingSection />
          {showReleases && hasRole(ROLES.calendar) && !releaseError && (
            <ReleaseUpcomingSection releases={upcomingReleases} />
          )}
          {!!collections?.length && <EducationCenterSection collections={collections} />}
          <QuickLinksSection />
        </main>

        <section>
          <UtilitySection />
        </section>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Home);

export async function getServerSideProps() {
  const upcomingReleases =
    !isTesting() &&
    (await apiService.releases.list({
      dateStart: new Date(),
      count: 4,
    }));

  const { collections } = await intercomService.helpCenter.collections();

  return {
    props: {
      upcomingReleases: upcomingReleases?.releases?.length ? upcomingReleases.releases : [],
      collections: collections || [],
      releaseError: !!upcomingReleases?.isError,
    },
  };
}
