import Config from '../config/Config';
import Collection from '../interfaces/collection';
import { ROLES, useAuth } from '../providers/Auth';
import EducationCenterCard from './EducationCenterCard';

interface EducationCenterSectionProps {
  collections: Collection[] | [];
}

const EducationCenterSection = ({ collections }: EducationCenterSectionProps) => {
  const { hasRole } = useAuth();
  const cardIcons = [
    hasRole(ROLES.plus) ? '/images/mobile.svg' : '/images/getting-started.svg',
    '/images/beginners-guide.svg',
    '/images/collect-guide.svg',
    '/images/release-prep.svg',
  ];
  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-labBold text-sm leading-sectionTitles text-gray-600 font-bold uppercase mb-5">
          Education Center
        </h1>
        {hasRole(ROLES.education) && (
          <a
            href={Config.EDUCATION_CENTER}
            target="_blank"
            className="font-medium text-gray-1000 mb-5 text-base hover:text-red-500"
            rel="noreferrer"
          >
            All resources<span className="pl-1 text-red-500 font-bold text-xs">&nbsp;&rarr;</span>
          </a>
        )}
      </div>
      <div>
        <div className="cardWrapper grid lg:grid-flow-col grid-cols-1 lg:grid-cols-4 gap-6">
          {collections.map((collection: Collection, index: number) => (
            <div
              key={collection.id}
              className={hasRole(ROLES.plus) && index !== 1 ? 'order-1' : ''}
            >
              <EducationCenterCard
                title={collection.name}
                description={collection.description}
                imageSrc={cardIcons[index]}
                link={`${collection.url}collections/${collection.id}`}
                isUpgrade={!hasRole(ROLES.education)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EducationCenterSection;
