import Config from '../config/Config';
import QuickLinkCard from './QuickLinkCard';
import { useFeatureHub } from '../providers/FeatureHub';

const formatStr = (str: string) => (
  <p>
    {str.split('**').map((element, index) =>
      index % 2 !== 0 &&
      (str.split('**').length % 2 !== 0 || str.split('**').length !== index + 1) ? (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} className="text-red-500">
          {element}
        </span>
      ) : (
        element
      )
    )}
  </p>
);

const QuickLinksSection = () => {
  const { features } = useFeatureHub();
  const { referPromo } = features;

  return (
    <div className="mb-12 mt-7">
      <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
        <QuickLinkCard
          title="Refer a friend"
          bgImage="bg-refBg"
          highlight={<p>NO REFERRAL LIMIT</p>}
          description={formatStr(referPromo)}
          links={[
            {
              cta: 'Refer a friend',
              url: `${Config.WORDPRESS_URL}/refer-a-friend`,
            },
          ]}
        />
        <QuickLinkCard
          title="Shop Online"
          bgImage="bg-storeBg"
          highlight={<span>&nbsp;</span>}
          description={
            <>
              Browse our shop to find sneakers and accessories for{' '}
              <span className="text-red-500">retail prices</span>
            </>
          }
          links={[{ cta: 'Shop now', url: Config.STORE }]}
        />
      </div>
    </div>
  );
};

export default QuickLinksSection;
