import { useCookies } from 'react-cookie';
import { MouseEvent, useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import SlackCommunity from './Steps/SlackCommunity';
import DropsApp from './Steps/DropsApp';
import StepControl from './StepControl';
import SlackInvitation from './Steps/SlackInvitation';
import EducationCenter from './Steps/EducationCenter';
import OneOnOne from './Steps/OneOnOne';
import { useIsBasic, ROLES, useAuth } from '../../providers/Auth';
import UpgradePlan from '../Upgrade/UpgradePlan';

export default function OnboardingSection() {
  const [onboardingCookieName, showOnboardingCookieName] = ['onboarding', 'showOnboarding'];
  const [cookie, setCookie] = useCookies([onboardingCookieName, showOnboardingCookieName]);
  const [showOnboard, setShowOnboard] = useState(
    cookie.showOnboarding === 'true' || !cookie.showOnboarding
  );
  const isBasic = useIsBasic();
  const { hasRole } = useAuth();

  let OnboardingCard;
  const toggleShowOnboarding = () => {
    setShowOnboard(!showOnboard);
    setCookie(showOnboardingCookieName, !showOnboard);
  };
  const toggleStep = (e: MouseEvent) => {
    setCookie(onboardingCookieName, e.currentTarget.id);
  };
  const increment = () => {
    setCookie(onboardingCookieName, +cookie.onboarding + 1);
  };
  const decrement = () => {
    setCookie(onboardingCookieName, +cookie.onboarding - 1);
  };
  const isBreakpoint = useMediaQuery('(max-width: 768px)');

  const steps: any[] = [];

  const getSteps = () => {
    if (hasRole(ROLES.slack)) steps.push(SlackInvitation, SlackCommunity);

    if (hasRole(ROLES.education)) steps.push(EducationCenter);

    // All groups will have this step
    steps.push(DropsApp);

    if (hasRole(ROLES.simulator)) steps.push(OneOnOne);

    if (hasRole(ROLES.upgradable)) steps.push(UpgradePlan);
  };

  if (isBasic) {
    OnboardingCard = UpgradePlan.component;
  } else {
    getSteps();
    OnboardingCard = steps[0].component;
    if (Number.isInteger(+cookie.onboarding) && +cookie.onboarding <= steps.length) {
      OnboardingCard = steps[cookie.onboarding - 1].component;
    } else {
      setCookie(onboardingCookieName, 1);
    }
  }

  return (
    <>
      <div className="relative h-6 w-full mb-4">
        <h1 className="font-labBold text-sm font-bold leading-sectionTitles text-gray-600 uppercase">
          {isBasic ? 'Upgrade Plan' : 'Getting Started'}
        </h1>
        <button
          id="dismiss"
          type="button"
          className="absolute right-0 inset-y-0 font-medium text-gray-1000 hover:text-red-500 flex items-center gap-2"
          onClick={toggleShowOnboarding}
        >
          <span>{showOnboard ? 'Hide' : 'Expand'}</span>
          <span>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={
                showOnboard ? 'transform -rotate-180 duration-75' : 'transform duration-75'
              }
            >
              <path d="M1.68213 1L6.68213 6L11.6821 1" stroke="#C50E11" strokeWidth="2" />
            </svg>
          </span>
        </button>
      </div>
      <>
        {showOnboard ? (
          <div className="p-4 bg-white rounded-lg shadow-sm mb-12">
            {isBreakpoint && !isBasic ? (
              <StepControl
                onboardingCardContent={{
                  ...steps[cookie.onboarding - 1].staticContent,
                  stepId: cookie.onboarding,
                }}
                onboardingState={cookie.onboarding}
                toggleFunction={toggleStep}
                incrementFunction={increment}
                decrementFunction={decrement}
                stepNum={steps.length}
              />
            ) : null}
            <div className="grid grid-cols-1 md:grid-cols-2">
              <OnboardingCard />
            </div>
            {/* <div className="hidden grid-cols-2" />
            <div className="hidden grid-cols-3" />
            <div className="hidden grid-cols-4" />
            <div className="hidden grid-cols-5" />
            <div className="hidden grid-cols-6" /> */}
            {!isBreakpoint && !isBasic && steps.length ? (
              <div className={`grid grid-cols-${steps.length} gap-2`}>
                {steps.map((step, index) => (
                  <StepControl
                    key={step.staticContent.title}
                    onboardingCardContent={{
                      ...steps[index].staticContent,
                      stepId: (index + 1).toString(),
                    }}
                    onboardingState={cookie.onboarding}
                    toggleFunction={toggleStep}
                    incrementFunction={increment}
                    decrementFunction={decrement}
                    stepNum={steps.length}
                  />
                ))}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="mb-12 sm:mb-16" />
        )}
      </>
    </>
  );
}
