import Avatar from './Avatar';

interface GreetingProps {
  displayName?: string;
}

export default function Greeting({ displayName }: GreetingProps) {
  const defaultGreeting: boolean = displayName !== '' || displayName === undefined;
  return (
    <div data-testid="greetingWrapper" className="gap-7 flex items-center">
      <Avatar userName={displayName} greeting />
      <div className="flex flex-col">
        {defaultGreeting ? (
          <>
            <p className="mb-2 capitalize">Hey {displayName},</p>
            <p>
              <b className="text-4xl font-tommyBold tracking-tight">Welcome home</b>
            </p>
          </>
        ) : (
          <>
            <p className="mb-2">Hey there,</p>
            <p>
              <b className="text-4xl font-tommyBold tracking-tight">Welcome home</b>
            </p>
          </>
        )}
      </div>
    </div>
  );
}

Greeting.defaultProps = {
  displayName: '',
};
